import { ApplicationRef, Injectable, inject } from "@angular/core";

import { DashboardService, HomeResponse } from "@core/api/dashboard.service";

import { Observable, interval } from "rxjs";

import { AccessService } from "./access.service";
import { DataBaseService } from "./base/data-base-service";
import { ProctorService } from "./proctor.service";

@Injectable({
  providedIn: "root",
})
export class HomeActionsService extends DataBaseService<HomeResponse> {
  appRef: ApplicationRef;
  accessService: AccessService;
  proctorService: ProctorService;
  dash = inject(DashboardService);

  interval$?: Observable<number>;

  refresher(): Observable<HomeResponse> {
    return this.dash.getHome();
  }

  /** Inserted by Angular inject() migration for backwards compatibility */
  constructor(...args: unknown[]);

  constructor() {
    const appRef = inject(ApplicationRef);
    const accessService = inject(AccessService);
    const proctorService = inject(ProctorService);

    super(appRef, accessService, proctorService);

    this.appRef = appRef;
    this.accessService = accessService;
    this.proctorService = proctorService;
  }

  init(): void {
    this.interval$ = interval(1000 * 60 * 2);
    this.interval$.subscribe({
      next: () => {
        this.getData(true).subscribe();
      },
    });
  }
}
